import React, { useEffect } from 'react';
import {
  Autocomplete,
  FormHelperText, TextField,
} from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { isEqual } from 'lodash';
import * as yup from 'yup';
import { AutocompleteControl } from '../FormControls';
import { AwoFormTimezoneOption } from '../../types/Awo';

export const timezoneSchema = {
  timezone: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).required('Time zone is required'),
};

function Location(
  {
    control, errors, selectedCountryOption, setValue, defaultValues,
  }:
  {
    control: Control<any, any>,
    errors: FieldErrors<{
      timezone: AwoFormTimezoneOption | null;
    }>,
    selectedCountryOption: Record<'label' | 'value' | 'timezones' | 'states', any[]> | null,
    setValue: (field: any, value: any) => void,
    defaultValues?: Record<string, any>,
  },
) {
  useEffect(() => {
    if (selectedCountryOption) {
      const tz = selectedCountryOption.timezones.find((timezoneItem) => timezoneItem.value === defaultValues?.timezone);
      if (tz) {
        setValue('timezone', tz);
      }
    }
  }, [defaultValues?.timezone, selectedCountryOption, setValue]);

  return (
    <AutocompleteControl
      fieldName="timezone"
      control={control}
      render={({ onChange, ...props }) => (
        <>
          <Autocomplete
            onChange={(event, value) => onChange({ target: { name: 'timezone', value } })}
            {...props}
            sx={{ marginTop: '16px' }}
            options={selectedCountryOption?.timezones || []}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                placeholder="Select time zone"
                label="Time zone"
              />
            )}
            isOptionEqualToValue={(...comp) => isEqual(...comp)}
            color={errors.timezone ? 'error' : 'primary'}
          />
          {errors.timezone && (
            <FormHelperText error>{errors.timezone.message}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
export default Location;
