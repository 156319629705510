import React from 'react';
import Typography from '@mui/material/Typography';
import {
  AppBar, Box, Grid, Toolbar,
} from '@mui/material';
import Copyright from './Copyright';

function SidebarPage({
  mobileHeader, greenWord, headerText, handleSubmit, FormComponent,
}) {
  return (
    <Grid container columns={10} component="main" sx={{ height: '100vh' }}>
      <AppBar sx={{
        bgcolor: 'primary.dark',
        display: {
          xs: 'block',
          sm: 'none',
        },
        top: 0,
        position: 'absolute',
      }}
      >
        <Toolbar>
          <Typography variant="h6bold">{mobileHeader}</Typography>
        </Toolbar>
      </AppBar>
      <Grid
        item
        sm={4}
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
          bgcolor: 'primary.dark',
        }}
      >
        <Box sx={{ height: '93vh', pt: '25vh', pl: '33%' }}>
          <Typography display="inline" fontWeight="bold" variant="h4" color="secondary.main">{greenWord}</Typography>
          <Typography display="inline" fontWeight="bold" variant="h4" color="white">{headerText}</Typography>
        </Box>
        <Box sx={{ height: '5vh', pb: '3vh', pl: '33%' }}>
          <Copyright color="white" />
        </Box>
      </Grid>
      <Grid item xs={10} sm={6}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            height: '93vh',
            pt: {
              xs: 8,
              sm: '25vh',
            },
            pl: {
              xs: 2,
              sm: '6.5%',
            },
            pr: {
              xs: 2,
            },
          }}
        >
          {FormComponent}
        </Box>
        <Box sx={{
          display: {
            xs: 'block',
            sm: 'none',
          },
          height: '5vh',
          pb: '3vh',
          textAlign: 'center',
        }}
        >
          <Copyright color="black" />
        </Box>
      </Grid>
    </Grid>
  );
}

export default SidebarPage;
