import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const namesSchema = {
  name: yup.string().required('Name is required'),
  displayName: yup.string().required('Display name is required'),
};

function NamesPartial() {
  return (
    <>
      <TextFieldElement
        name="name"
        sx={{ marginTop: '16px' }}
        fullWidth
        label="Name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="Used in the AWO Dashboard and formal purposes. Example: ‘Friends of Dogs at Guadalupe County Animal Control’"
      />
      <TextFieldElement
        name="displayName"
        sx={{ marginTop: '16px' }}
        fullWidth
        label="Display name"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        helperText="The name we use when sending SMS messages to pet parents. Example: ‘Friends of Dogs’"
      />
    </>
  );
}
export default NamesPartial;
