import React from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  LinearProgress, Paper, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { hashids } from '../utilities';
import EnhancedTableHeader from './EnhancedTableHeader';
import TablePaginationActions from './TablePaginationActions';
import { PetParentTableView } from '../types/PetParent';

type ProfilesProps = {
  rowsPerPage: number,
  rowsPerPageOptions?: Array<number | { value: number; label: string }>,
  page: number,
  count: number,
  orderBy: string,
  order: string,
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
  handleChangeOrder: (event: unknown, id: string) => void,
  loading: boolean,
  petParents: PetParentTableView[],
  networkStatus: unknown,
  headCells: { id: string, width: string, label: string, render: (row: PetParentTableView) => React.ReactElement }[]
};

function ProfilesTable({
  rowsPerPage, rowsPerPageOptions, page, count, orderBy, order, handleChangeRowsPerPage, handleChangePage,
  handleChangeOrder, loading, petParents, networkStatus, headCells,
}: ProfilesProps) {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: '500px' }} aria-label="Courses Table" size="small">
        <EnhancedTableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          handleChangeOrder={handleChangeOrder}
        />
        {loading || networkStatus === NetworkStatus.refetch ? <LinearProgress sx={{ display: 'table-header-group' }} /> : null}
        <TableBody>
          {!loading && petParents.map((row) => (
            <TableRow
              key={`${row.id}-${row.animalType}-${row.careType}`}
              hover
              onClick={() => {
                navigate(`/profiles#id=${hashids.encode(row.id)}`);
              }}
            >
              {
                headCells.map((cell) => cell.render(row))
              }
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default ProfilesTable;
