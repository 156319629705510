import React from 'react';
import { Paper } from '@mui/material';

function StyledPaper({ children, color }: { children: React.ReactNode, color: string }) {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2, mt: 2, bgcolor: color, borderRadius: '8px',
      }}
    >
      {children}
    </Paper>
  );
}

export default StyledPaper;
