import React from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Chip,
  LinearProgress,
  Table,
  TableBody,
  TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TablePaginationActions from './TablePaginationActions';
import { Awo } from '../types/Awo';
import { OrganizationsTableFields } from '../types/Tables';
import { automatedSMSNames } from '../utilities';

interface OrganizationsTableProps {
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  page: number;
  count: number;
  orderBy: string | null;
  order: 'desc' | 'asc' | undefined;
  handleChangeRowsPerPage: (event: any) => void;
  handleChangePage: (event: any, newPage: number) => void;
  handleChangeOrder: (event: any, field: OrganizationsTableFields) => void;
  loading: boolean;
  organizations: Awo[];
  networkStatus: NetworkStatus;
}

function OrganizationsTable({
  rowsPerPage,
  rowsPerPageOptions,
  page,
  count,
  orderBy,
  order,
  handleChangeRowsPerPage,
  handleChangePage,
  handleChangeOrder, loading, organizations, networkStatus,
}: OrganizationsTableProps) {
  const headCells = [{
    id: 'name',
    label: 'Name',
    width: '30%',
  }, {
    id: 'city',
    label: 'Location',
    width: '20%',
  }, {
    id: 'members',
    label: 'Members',
    width: '10%',
  }, {
    id: 'sms_name',
    label: 'SMS',
    width: '10%',
  }, {
    id: 'status',
    label: 'Status',
    width: '10%',
  }, {
    id: 'lastSyncDate',
    label: 'Last sync',
    width: '10%',
  }, {
    id: 'membership_type',
    label: 'Type',
    width: '10%',
  }];

  const smsNamesDict: Record<string, string> = {
    Shelterluv: 'Shelterluv',
    'Shelter Buddy': 'Shelter Buddy',
    Petstablished: 'Petstablished',
    Pawlytics: 'Pawlytics',
    'Animal Shelter Manager': 'ASM',
    'Animals First': 'Animals First',
    Chameleon: 'Chameleon',
    Petpoint: 'Petpoint',
    Pethealth: 'Pethealth',
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleChangeOrder(event, property);
  };
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table sx={{ minWidth: '500px' }} aria-label="Organizations Table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{
                  width: headCell.width,
                }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || networkStatus === NetworkStatus.refetch ? <tr><td colSpan={8} aria-label="Loading progress"><LinearProgress /></td></tr> : null}
          {!loading && organizations.map((row) => (
            <TableRow
              key={`${row.id}_${row.name}`}
              hover
              onClick={() => {
                navigate(`/organizations/${row.id}/settings`);
              }}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {row.name}
              </TableCell>
              <TableCell sx={{
                maxWidth: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              >
                {
                  [row.city, row.state].filter((item) => item).join(', ')
                }
              </TableCell>
              <TableCell>{row.members}</TableCell>
              <TableCell>
                {smsNamesDict[row.smsName] || row.smsName}
              </TableCell>
              <TableCell>
                <Chip
                  label={row.status}
                  size="small"
                  disabled={row.status === 'Deactivated'}
                  sx={{
                    '& .MuiChip-label': {
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </TableCell>
              {
                !automatedSMSNames.includes(row.smsName)
                || row.status === 'Deactivated' ? (
                  <TableCell>
                    <Chip
                      label="N/A"
                      size="small"
                    />
                  </TableCell>
                ) : (
                  <TableCell>
                    <Chip
                      label={row.lastSyncDate ? 'Success' : 'Error'}
                      size="small"
                      sx={{
                        bgcolor: row.lastSyncDate ? 'secondary.main' : 'error.light',
                        '& .MuiChip-label': {
                          color: '#fff',
                          textTransform: 'capitalize',
                        },
                      }}
                    />
                  </TableCell>
                )
              }
              <TableCell>
                <Chip
                  label={row.membershipType}
                  size="small"
                  sx={{
                    bgcolor: row.membershipType === 'Plus' ? 'primary.main' : 'secondary.main',
                    '& .MuiChip-label': {
                      color: '#fff',
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={8}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default OrganizationsTable;
