import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const websiteSchema = {
  website: yup.string().url('Website must be a valid URL (including http:// or https://)').required('Website is required'),
};
function WebsitePartial() {
  return (
    <TextFieldElement
      name="website"
      sx={{ marginTop: '16px' }}
      fullWidth
      label="Website"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default WebsitePartial;
