import React from 'react';
import {
  Box, Checkbox, FormControlLabel, FormHelperText, FormLabel, Grid, Typography,
} from '@mui/material';
import {
 Control, FieldErrors, useForm, useWatch,
} from 'react-hook-form';
import { CheckboxElement } from 'react-hook-form-mui';
import * as yup from 'yup';

export const courseSelectionSchema = {
  preSurrenderDashboard: yup.boolean(),
  dogAdopt: yup.boolean(),
  catAdopt: yup.boolean(),
  dogFoster: yup.boolean(),
  catFoster: yup.boolean(),
  kittenFoster: yup.boolean(),
  checkboxSelection: yup.boolean().when(
    ['dogAdopt', 'catAdopt', 'dogFoster', 'catFoster', 'kittenFoster', 'preSurrenderDashboard'],
    ([dogAdopt, catAdopt, dogFoster, catFoster, kittenFoster, preSurrenderDashboard], checkboxSchema) => (
      preSurrenderDashboard || dogAdopt || catAdopt || dogFoster || catFoster || kittenFoster ? checkboxSchema : checkboxSchema.required('Pre-Surrender support or at least one animal selection is required')
    ),
  ),
};

function SupportedAnimalsPartial(
  { control, includeSubtext }:
    {
      control: Control<any, any>,
      includeSubtext?: boolean,
    },
) {
  const dogFosterValue = useWatch({ control, name: 'dogFoster' });
  const catFosterValue = useWatch({ control, name: 'catFoster' });
  const kittenFosterValue = useWatch({ control, name: 'kittenFoster' });
  const dogAdoptValue = useWatch({ control, name: 'dogAdopt' });
  const catAdoptValue = useWatch({ control, name: 'catAdopt' });
  const isNotPreSurrender = dogFosterValue || catFosterValue || kittenFosterValue || dogAdoptValue || catAdoptValue;

  return (
    <>
      <Typography variant="body1">Supported animals</Typography>
      {includeSubtext ? (
        <FormLabel sx={{
          fontSize: '12px',
        }}
        >
          Controls which Thinkific courses are available to pet parents;
          shows or hides pages from the AWO dashboard;
          selects the types of emails sent to pet parents; and more
        </FormLabel>
      ) : null}
      <Box>
        <Box>
          <CheckboxElement
            disabled={isNotPreSurrender}
            control={control}
            label="This organization is pre-surrender only"
            name="preSurrenderDashboard"
          />
        </Box>
      </Box>
    </>
  );
}

function AdoptedAnimalsPartial(
  { control, setValue, preSurrenderActive }:
    {
      control: Control<any, any>,
      setValue: (field: any, value: any) => void,
      preSurrenderActive: boolean,
    },
) {
  const dogAdoptValue = useWatch({ control, name: 'dogAdopt' });
  const catAdoptValue = useWatch({ control, name: 'catAdopt' });

  const basicComparison = dogAdoptValue === catAdoptValue;

  const groupCheckboxHandler = (e: any) => {
    setValue('dogAdopt', e.target.checked);
    setValue('catAdopt', e.target.checked);
  };

  return (
    <>
      <Box>
        <FormControlLabel control={<Checkbox disabled={preSurrenderActive} onClick={groupCheckboxHandler} checked={!!dogAdoptValue && basicComparison} indeterminate={!basicComparison} />} label="Adopted animals" />
      </Box>
      <Box sx={{ ml: 3 }}>
        <Box>
          <CheckboxElement
            control={control}
            label="Dogs"
            name="dogAdopt"
            disabled={preSurrenderActive}
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Cats"
            name="catAdopt"
            disabled={preSurrenderActive}
          />
        </Box>
      </Box>
    </>
  );
}

function FosteredAnimalsPartial(
  { control, setValue, preSurrenderActive }:
    {
      control: Control<any, any>,
      setValue: (field: any, value: any) => void,
      preSurrenderActive: boolean,
    },
) {
  const dogFosterValue = useWatch({ control, name: 'dogFoster' });
  const catFosterValue = useWatch({ control, name: 'catFoster' });
  const kittenFosterValue = useWatch({ control, name: 'kittenFoster' });

  const basicComparison = dogFosterValue === catFosterValue && catFosterValue === kittenFosterValue;

  const groupCheckboxHandler = (e: any) => {
    setValue('dogFoster', e.target.checked);
    setValue('catFoster', e.target.checked);
    setValue('kittenFoster', e.target.checked);
  };

  return (
    <>
      <Box>
        <FormControlLabel control={<Checkbox disabled={preSurrenderActive} onClick={groupCheckboxHandler} checked={!!dogFosterValue && basicComparison} indeterminate={!basicComparison} />} label="Fostered animals" />
      </Box>
      <Box sx={{ ml: 3 }}>
        <Box>
          <CheckboxElement
            control={control}
            label="Dogs"
            name="dogFoster"
            disabled={preSurrenderActive}
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Cats"
            name="catFoster"
            disabled={preSurrenderActive}
          />
        </Box>
        <Box>
          <CheckboxElement
            control={control}
            label="Kittens"
            name="kittenFoster"
            disabled={preSurrenderActive}
          />
        </Box>
      </Box>
    </>
  );
}

function AnimalsPartial(
  {
 control, errors, setValue, includeSubtext,
}:
    {
      control: Control<any, any>,
      errors: FieldErrors<{ checkboxSelection: boolean | null }>,
      includeSubtext?: boolean,
      setValue: (field: any, value: any) => void,
    },
) {
  const preSurrenderDashboardValue = useWatch({ control, name: 'preSurrenderDashboard' });
  return (
    <>
      <Grid item>
        <SupportedAnimalsPartial control={control} includeSubtext={includeSubtext} />
      </Grid>
      <Grid item>
        <AdoptedAnimalsPartial preSurrenderActive={preSurrenderDashboardValue} setValue={setValue} control={control} />
      </Grid>
      <Grid item>
        <FosteredAnimalsPartial preSurrenderActive={preSurrenderDashboardValue} setValue={setValue} control={control} />
        {errors.checkboxSelection && (
          <FormHelperText sx={{ ml: 0 }} error>{errors.checkboxSelection?.message}</FormHelperText>
        )}
      </Grid>
    </>
  );
}

export default AnimalsPartial;
