import React from 'react';
import {
  Alert,
  Box,
  Divider,
  Grid, IconButton,
  List,
  Snackbar,
  Typography,
} from '@mui/material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import {
 ContentCopy, DownloadOutlined,
} from '@mui/icons-material';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

import { Awo } from '../../types/Awo';
import SettingsListItem from '../SettingsListItem';
import FeedbackScoresPartial, { feedbackScoresSchema } from '../FormPartials/FeedbackScoresPartial';
import OtherTasksPartial, { otherTasksSchema } from '../FormPartials/OtherTasksPartial';
import { phoneFormatter } from '../../utilities';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function SettingsPanel({ awo }: { awo: Awo | Record<string, never> }) {
  const isCoSheltering = awo?.coSheltering;
  const isPreSurrender = awo?.preSurrenderDashboard;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  if (isEmpty(awo)) return null;
  const adoptionFlyer = awo.awoFlyers.find((awoFlyer) => awoFlyer.pdfMonkeyUrl.url.includes('Adopt'));
  const preSurrenderFlyer = awo.awoFlyers.find((awoFlyer) => awoFlyer.pdfMonkeyUrl.url.includes('Pre-Surrender'));
  return (
    <>
      <Box sx={{ maxWidth: 800, width: '100%', mx: 'auto' }}>
        <Alert severity="info">Changes made to your settings will be applied to all members within your organization.</Alert>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 3 }}>
              <Typography variant="overline" color="primary.text">Settings</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List sx={{ pt: 2 }}>
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'SMS Number',
                  description: 'Phone number pet parents can text for support. Click to copy.',
                  displayValue: phoneFormatter(awo.twilioPhoneNumber),
                }]}
                onClick={() => {
                  navigator.clipboard.writeText(phoneFormatter(awo.twilioPhoneNumber));
                  setSnackbarMessage('SMS number copied');
                  setSnackbarOpen(true);
                }}
                icon={(
                  <IconButton>
                    <ContentCopy sx={{ color: 'secondary.text' }} />
                  </IconButton>
                )}
              />
              <SettingsListItem
                awo={awo}
                title="Task created when a pet parent..."
                headings={[{
                  title: 'Task created when',
                  snackTitle: 'Task settings',
                  description: 'Change the triggers when a Petcademy task is created',
                  displayValue: '6 selections',
                }]}
                defaultValues={{
                  feedback1: awo.taskSettings.feedback1,
                  feedback2: awo.taskSettings.feedback2,
                  feedback3: awo.taskSettings.feedback3,
                  feedback4: awo.taskSettings.feedback4,
                  feedback5: awo.taskSettings.feedback5,
                  asksQuestion: awo.taskSettings.asksQuestion,
                  emailTypo: awo.taskSettings.emailTypo,
                  phoneTypo: awo.taskSettings.phoneTypo,
                  adoptionReview: awo.taskSettings.adoptionReview,
                  fosterReview: awo.taskSettings.fosterReview,
                  unresponsive: awo.taskSettings.unresponsive,
                  completedSupport: awo.taskSettings.completedSupport,
                }}
                schema={yup.object({ ...feedbackScoresSchema, ...otherTasksSchema })}
              >
                {(control) => (
                  <>
                    <Grid item>
                      <FeedbackScoresPartial control={control} />
                    </Grid>
                    <Grid item>
                      <OtherTasksPartial
                        control={control}
                        preSurrenderDashboard={isPreSurrender}
                        coSheltering={isCoSheltering}
                      />
                    </Grid>
                  </>
                )}
              </SettingsListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ pt: 2 }}>
              <Typography variant="overline" color="primary.text">Support</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ pl: { xs: 0 } }}>
            <List sx={{ pt: 1 }}>
              {!isPreSurrender && !isCoSheltering ? (
                <SettingsListItem
                  awo={awo}
                  headings={[{
                    title: 'Adopter/Foster Flyer',
                    description: 'Give to pet parents as part of their adoption/foster packets',
                    displayValue: null,
                  }]}
                  link={{
                    href: adoptionFlyer?.pdfMonkeyUrl.url || '#',
                    target: '_blank',
                    rel: 'noreferrer',
                  }}
                  icon={(
                    <IconButton>
                      <DownloadOutlined />
                    </IconButton>
                  )}
                />
              ) : null}
              {!isCoSheltering && (
                <SettingsListItem
                  awo={awo}
                  headings={[{
                    title: 'Diversion Flyer',
                    description: 'Give to pet parents considering surrendering their pet',
                    displayValue: null,
                  }]}
                  link={{
                    href: preSurrenderFlyer?.pdfMonkeyUrl.url || '#',
                    target: '_blank',
                    rel: 'noreferrer',
                  }}
                  icon={(
                    <IconButton>
                      <DownloadOutlined />
                    </IconButton>
                  )}
                />
              )}
              <SettingsListItem
                awo={awo}
                headings={[{
                  title: 'Email support',
                  description: 'Copy the email address for our support team',
                  displayValue: null,
                }]}
                onClick={() => {
                  navigator.clipboard.writeText('bailey@petcademy.org');
                  setSnackbarMessage('Email address copied');
                  setSnackbarOpen(true);
                }}
                icon={(
                  <IconButton>
                    <ContentCopy sx={{ color: 'secondary.text' }} />
                  </IconButton>
                )}
              />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbarMessage}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
}

export default SettingsPanel;
