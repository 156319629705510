import {
  Alert,
  AlertColor,
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Snackbar,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import * as Sentry from '@sentry/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { AwoUserMutationResponse, NewAwoForm, NewAwoFormValues } from '../../types/Awo';
import { beforeCapture, parseAwoCreateFormValues } from '../../utilities';
import countriesOptions from '../../resources/countriesData';
import { CREATE_AWO } from '../../queries';
import LocationPartial, { locationSchema } from '../FormPartials/LocationPartial';
import WebsitePartial, { websiteSchema } from '../FormPartials/WebsitePartial';
import NamesPartial, { namesSchema } from '../FormPartials/NamesPartial';
import Timezone, { timezoneSchema } from '../FormPartials/TimezonePartial';
import RescueTypePartial, { rescueTypeSchema } from '../FormPartials/RescueTypePartial';
import NotePartial from '../FormPartials/NotePartial';
import ShelterManagementPartial, { shelterManagementSchema } from '../FormPartials/ShelterManagementPartial';
import AnimalsPartial, { courseSelectionSchema } from '../FormPartials/AnimalsPartial';

const defaultValues: NewAwoForm = {
  name: '',
  displayName: '',
  smsName: null,
  token: '',
  apiUrl: '',
  apiKey: '',
  note: '',
  rescueType: null,
  website: '',
  country: null,
  address: '',
  city: '',
  state: null,
  postal: undefined,
  timezone: null,
  petcademyPlan: '',
  checkboxSelection: false,
  googleDriveFolder: '',
  dogAdopt: false,
  catAdopt: false,
  dogFoster: false,
  catFoster: false,
  kittenFoster: false,
  firstTeamMemberFirstName: '',
  firstTeamMemberLastName: '',
  firstTeamMemberEmail: '',
  notifications: true,
};

function NewOrganizationModal({ onClose }: { onClose: (awoName?: string) => void }) {
  const [selectedCountryOption, setSelectedCountryOption] = React.useState<Record<'label' | 'value' | 'timezones' | 'states', any> | null>(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('error');

  const [createAwo, { loading }] = useMutation(CREATE_AWO, {
    onCompleted: (data: { createAwo: AwoUserMutationResponse }) => {
      const response = data.createAwo;
      setSnackbarOpen(true);
      setSnackbarMessage(response.message);
      if (!response.success) {
        setSnackbarSeverity('error');
      } else {
        setSnackbarSeverity('success');
        onClose(response.awo.name);
      }
    },
    onError: (e) => {
      Sentry.withScope((scope) => {
        beforeCapture(scope, e);
        Sentry.captureException(e);
      });
      setSnackbarOpen(true);
      setSnackbarSeverity('error');
      setSnackbarMessage('Error in creating a new organization. We\'re working on it.');
    },
  });

  const schema = yup.object({
    ...namesSchema,
    ...shelterManagementSchema,
    ...rescueTypeSchema,
    ...websiteSchema,
    ...locationSchema,
    ...timezoneSchema,
    ...courseSelectionSchema,
    firstTeamMemberFirstName: yup.string().required('First name is required'),
    firstTeamMemberLastName: yup.string().required('Last name is required'),
    firstTeamMemberEmail: yup.string().email('Must be a valid email').matches(/@[^.]*\./, 'Must be a valid email').required('Email is required'),
  }).required();

  const {
    register, control, formState, setValue, getValues, ...rest
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  });

  const { errors } = formState;

  useEffect(() => {
    dayjs.extend(timezone);
    const guessedCountry = (selectedCountryOption?.value) || dayjs.tz.guess().split('/')[0];
    const guessedCountryOption = countriesOptions.find((country) => country.value.includes(guessedCountry));
    if (guessedCountryOption) {
      setSelectedCountryOption(guessedCountryOption);
    }
  }, [selectedCountryOption?.value]);

  const formSubmitHandler = async (values: Required<NewAwoFormValues>) => {
    const strictValues = parseAwoCreateFormValues(values);
    await createAwo({
      variables: {
        awo: {
          ...strictValues,
        },
      },
    });
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        key={snackbarMessage}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage || 'Error in creating a new organization. We\'re working on it.'}</Alert>
      </Snackbar>
      <AppBar sx={{
        position: 'fixed',
        boxShadow: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        bgcolor: '#fff',
        color: 'primary.contrastLight',
      }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => onClose()}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1, color: 'primary.textBlack' }} variant="h5" component="div">
            Create new organization
          </Typography>
          <Chip
            color="info"
            label="Submit this form to create a new organization. All fields are required unless marked."
          />
        </Toolbar>
      </AppBar>
      <Box px={2} pt={8} pb={6} maxWidth="800px" width="100%" mx="auto">
        <FormContainer
          formContext={{
            register, control, formState, setValue, getValues, ...rest,
          }}
          onSuccess={formSubmitHandler as any}
        >
          <Grid
            sx={{ flexWrap: { md: 'nowrap' }, padding: { xs: '20px 0px', md: '56px 0px' } }}
            columns={14}
            container
            spacing={2}
          >
            <Grid item xs={14} md={4}>
              <Typography variant="overline">
                External connection
              </Typography>
            </Grid>
            <Grid item xs={14} md={10} container direction="column" gap="40px">
              <Grid item>
                <Box sx={{ marginBottom: '16px' }}>
                  <Typography variant="body1">
                    Shelter management software
                  </Typography>
                  <Typography variant="caption">
                    Select the software this organization uses to manage animals. Next steps may appear below.
                  </Typography>
                </Box>
                <ShelterManagementPartial
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            sx={{ flexWrap: { md: 'nowrap' }, padding: { xs: '20px 0px', md: '56px 0px' } }}
            columns={14}
            container
            spacing={2}
          >
            <Grid xs={14} md={4} item>
              <Typography variant="overline">
                Organization
              </Typography>
            </Grid>
            <Grid xs={14} md={10} item container direction="column" gap="40px">
              <Grid item>
                <Typography variant="body1">
                  Names
                </Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  How this organization is labeled in different situations
                </FormLabel>
                <NamesPartial />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Note
                </Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Add a note for any unique
                  details about this organization. Only Petcademy staff can see it.
                </FormLabel>
                <NotePartial />
              </Grid>
              <Grid item>
                <RescueTypePartial control={control} errors={errors} />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Website
                </Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Website for this
                  organization
                </FormLabel>
                <WebsitePartial />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Location
                </Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Enter the address for this
                  organization, used to generate SMS number
                </FormLabel>
                <LocationPartial
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  selectedCountryOption={selectedCountryOption}
                  setSelectedCountryOption={setSelectedCountryOption}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Time zone
                </Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Select time zone used to
                  determine when emails and SMS messages are sent
                </FormLabel>
                <Timezone
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  selectedCountryOption={selectedCountryOption}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            sx={{ flexWrap: { md: 'nowrap' }, padding: { xs: '20px 0px', md: '56px 0px' } }}
            columns={14}
            container
            spacing={2}
          >
            <Grid xs={14} md={4} item>
              <Typography variant="overline">
                Animals
              </Typography>
            </Grid>
            <Grid xs={14} md={10} item container direction="column" gap="40px">
              <AnimalsPartial setValue={setValue} control={control} errors={errors} includeSubtext />
            </Grid>
          </Grid>
          <Divider />
          <Grid
            sx={{ flexWrap: { md: 'nowrap' }, padding: { xs: '20px 0px', md: '56px 0px' } }}
            columns={14}
            container
            spacing={2}
          >
            <Grid xs={14} md={4} item>
              <Typography variant="overline">
                Invite
              </Typography>
            </Grid>
            <Grid xs={14} md={10} item container direction="column" gap="40px">
              <Grid item>
                <Typography variant="body1">First team member</Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Send an invitation email to the
                  first team member for this organization. You can add additional members afterwards.
                </FormLabel>
                <TextFieldElement
                  name="firstTeamMemberFirstName"
                  sx={{ marginTop: '16px' }}
                  fullWidth
                  label="First Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextFieldElement
                  name="firstTeamMemberLastName"
                  sx={{ marginTop: '16px' }}
                  fullWidth
                  label="Last Name"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextFieldElement
                  name="firstTeamMemberEmail"
                  sx={{ marginTop: '16px' }}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item>
                <Typography variant="body1">Notifications</Typography>
                <FormLabel sx={{ fontSize: '12px' }}>
                  Sends an email at the end of the
                  day if there are outstanding tasks
                </FormLabel>
                <FormGroup {...register('notifications')} sx={{ marginTop: '16px' }}>
                  <FormControlLabel
                    control={<Switch name="notifications" defaultChecked />}
                    label="Task notifications via email"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
              background: '#fff',
              borderTop: '1px solid #E0E0E0',
              zIndex: 2,
            }}
            py={2}
            px={6}
          >
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
            >
              {loading ? 'Loading' : 'Create new organization'}
            </Button>
          </Box>
        </FormContainer>
      </Box>
    </>
  );
}

export default NewOrganizationModal;
