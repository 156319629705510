import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  ChatOutlined,
  ContactPageOutlined,
  DashboardOutlined,
  LogoutOutlined,
  PersonAddOutlined,
  Preview,
  PhotoLibraryOutlined,
  SchoolOutlined,
  SettingsOutlined,
  PollOutlined,
  Grid3x3Outlined,
  QrCodeOutlined, SmsOutlined, GroupsOutlined,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  Link,
  Skeleton,
  Toolbar,
  useMediaQuery,
  useTheme,
  CardHeader, CardContent, CardActions, Switch, FormControlLabel,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_AWO } from '../queries';
import AwoAutocomplete, { AutocompleteAwo } from './AwoAutocomplete';
import AwoContext from './AwoContextProvider';
import NavigationItem from './NavigationItem';
import CustomDrawer from '../Drawer';
import { Awo } from '../types/Awo';

function WithSideNav({ fetchAwo = true, children }: { fetchAwo: boolean; children: ReactNode | ReactNode[] }) {
  const { client, loading, data } = useQuery<{ awo: Awo }>(GET_AWO, { skip: !fetchAwo });
  const petcademyTheme = useTheme();
  const [showDeactivated, setShowDeactivated] = React.useState(JSON.parse(sessionStorage.getItem('showDeactivated') || 'false'));
  const isDesktop = useMediaQuery(petcademyTheme.breakpoints.up('md'), {
    noSsr: true,
  });
  const identity = useIdentityContext();
  const isAdmin = get(identity, 'user.app_metadata.roles[0]') === 'admin';
  const isAgent = get(identity, 'user.app_metadata.roles', []).includes('agent');
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const [selected, setSelected] = React.useState(location.pathname);
  const [awoDialogOpen, setAwoDialogOpen] = React.useState(false);
  const [adminAwo, setAdminAwo] = React.useState<AutocompleteAwo | null>(null);

  const handleShowDeactivatedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    sessionStorage.setItem('showDeactivated', `${newValue}`);
    setShowDeactivated(newValue);
  };

  const logout = async () => {
    localStorage.removeItem('ni.goTrueToken');
    navigate('/');
    identity.logout().then(() => client.clearStore());
  };

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);

  const navItems = [
    {
      name: 'dashboard',
      Icon: DashboardOutlined,
      title: 'Dashboard',
      visible: !loading,
    }, {
      name: 'cases',
      Icon: ContactPageOutlined,
      title: 'Cases',
      visible: !loading && data?.awo?.preSurrenderDashboard,
    }, {
      name: 'feedback',
      Icon: ChatOutlined,
      title: 'Feedback',
      visible: !loading && !data?.awo?.preSurrenderDashboard && !data?.awo?.coSheltering,
    }, {
      name: 'courses',
      Icon: SchoolOutlined,
      title: 'Courses',
      visible: !loading && !data?.awo?.preSurrenderDashboard && !data?.awo?.coSheltering,
    }, {
      name: 'profiles',
      Icon: ContactPageOutlined,
      title: 'Profiles',
      visible: !loading && !data?.awo?.preSurrenderDashboard,
    }, {
      name: 'invite',
      Icon: PersonAddOutlined,
      title: 'Invite',
      visible: !loading,
    }, {
      name: 'qr-code',
      Icon: QrCodeOutlined,
      title: 'QR Code',
      visible: !loading && !data?.awo?.preSurrenderDashboard && !data?.awo?.coSheltering,
    }, {
      name: 'settings',
      Icon: SettingsOutlined,
      title: 'Admin',
      visible: !loading,
    }, {
      name: 'logout',
      onClick: () => setOpen(true),
      Icon: LogoutOutlined,
      title: 'Logout',
      visible: !loading,
    },
  ];

  const adminNavItems = [
    {
      name: 'viewAs',
      onClick: () => setAwoDialogOpen(true),
      Icon: Preview,
      title: 'View As',
      visible: isAdmin,
    }, {
      name: 'tag-review',
      Icon: Grid3x3Outlined,
      title: 'Tag Review',
      visible: isAdmin,
    }, {
      name: 'organizations',
      Icon: GroupsOutlined,
      title: 'Organizations',
      visible: !loading && isAdmin && !isAgent,
    }, {
      name: 'photos',
      Icon: PhotoLibraryOutlined,
      title: 'Photos',
      visible: !loading && isAdmin,
    }, {
      name: 'sms',
      Icon: SmsOutlined,
      title: 'SMS',
      visible: !loading && isAdmin && !isAgent,
    }, {
      name: 'typeforms',
      Icon: PollOutlined,
      title: 'Typeforms',
      visible: !loading && isAdmin && !isAgent,
    },
  ];

  const loadingNavItems = new Array(navItems.length).fill(true).map((_, idx) => ({
    name: `Loading ${idx}`,
    onClick: () => {},
    title: <Skeleton sx={{ bgcolor: 'primary.contrastText' }} variant="text" />,
    visible: loading,
  }));

  return (
    <AwoContext awo={useMemo(() => ({
      awo: data?.awo || {} as Awo,
      loading,
    }), [data?.awo, loading])}
    >
      <Box sx={{ display: 'flex' }}>
        <AppBar sx={{
          bgcolor: 'primary.main',
          display: {
            xs: 'block',
            md: 'none',
          },
          top: 0,
          pl: 0.5,
          pr: 0.5,
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        >
          <Toolbar>
            <IconButton
              sx={{
                padding: 1.75,
                minWidth: '0',
              }}
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <img style={{ width: '25px' }} alt="Petcademy Logo" src="/Petcademy_icon_transparent.png" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <CustomDrawer
          variant={isDesktop ? 'persistent' : 'temporary'}
          open={drawerOpen}
          desktop={isDesktop}
          onClose={toggleDrawer}
          onMouseEnter={() => setDrawerOpen(true)}
          onMouseLeave={() => setDrawerOpen(false)}
        >
          <Box
            sx={{
              pt: {
                xs: 5,
                md: 2,
              },
              pb: 1.38,
              pl: 2,
              pr: 2,
            }}
          >
            {
                isDesktop && (
                  <Button
                    sx={{
                      pl: 2,
                      pr: 2,
                      ml: -2,
                      mr: -2,
                      pt: 1,
                      pb: 1,
                      minWidth: '0',
                    }}
                    onClick={toggleDrawer}
                  >
                    <img style={{ width: '25px' }} alt="Petcademy Logo" src="/Petcademy_icon_transparent.png" />
                  </Button>
                )
              }
            <Box sx={{ visibility: drawerOpen ? 'visible' : 'hidden' }}>
              <Typography variant="h6bold" component="h6" color="white">Petcademy</Typography>
              {!isAdmin && <Typography color="primary.200" variant="body2">{get(data, 'awo.name', '')}</Typography>}
              {isAdmin ? (
                <div>
                  {
                    loading
                      ? <Skeleton sx={{ bgcolor: 'primary.contrastText', fontSize: '0.85rem' }} variant="text" />
                      : (
                        <Typography
                          sx={{
                            whiteSpace: 'normal',
                            maxHeight: 64,
                            width: 208,
                            overflow: 'hidden',
                          }}
                          color="primary.200"
                          variant="body2"
                        >
                          {`${localStorage.getItem('awoName') === 'Petcademy Admin' ? 'Petcademy Admin' : `Viewing dashboard as ${localStorage.getItem('awoName') || 'Petcademy Admin'}`}`}
                        </Typography>
                      )
                  }
                </div>
              ) : null}
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: drawerOpen ? 'auto' : 'hidden',
              overflowX: 'hidden',
              flexGrow: 1,
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: petcademyTheme.palette.primary.light,
              },
            }}
          >
            <List>
              {
                loadingNavItems.map((nav) => <NavigationItem {...nav} key={nav.name} />)
              }
              {
                navItems.map((nav) => <NavigationItem {...nav} selected={selected} key={nav.name} />)
              }
              {
                isAdmin && (
                <Box
                  sx={{
                    my: 0.5, width: '100%', height: '1px', bgcolor: 'primary.800',
                  }}
                />
                )
              }
              {
                adminNavItems.map((nav) => <NavigationItem {...nav} selected={selected} key={nav.name} />)
              }
            </List>
          </Box>
          {
              drawerOpen && (
                <Box
                  sx={{
                    color: 'primary.200',
                    px: 2,
                    pt: 1,
                    pb: 2,
                    fontSize: '0.8rem',
                  }}
                >
                  <Link
                    href="/privacy-policy"
                    underline="hover"
                    color="primary.200"
                    rel="noopener"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  {' '}
                  •
                  {' '}
                  <Link
                    href="/terms-and-conditions"
                    underline="hover"
                    color="primary.200"
                    rel="noopener"
                    target="_blank"
                  >
                    Terms
                  </Link>
                </Box>
              )
            }
        </CustomDrawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: '100vh',
            bgcolor: '#FFF',
            width: '100%',
            overflowX: 'hidden',
            pt: {
              xs: 7,
              md: 0,
            },
          }}
        >
          {children}
        </Box>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="logout-dialog-title"
          aria-describedby="logout-dialog-description"
        >
          <DialogTitle id="logout-dialog-title">
            Logout
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to logout of Petcademy?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={logout}>Logout</Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={awoDialogOpen}
          onClose={() => setAwoDialogOpen(false)}
          aria-labelledby="awo-dialog"
          aria-describedby="awo-dialog-description"
          maxWidth={false}
          PaperProps={{
            sx: {
              width: '584px',
              maxWidth: '90vw',
              overflowY: 'unset',
            },
          }}
        >
          <CardHeader title="View As" subheader="Select the organization to view" />
          <CardContent sx={{ overflowY: 'unset' }}>
            <AwoAutocomplete
              selectedAwo={adminAwo}
              setAwo={setAdminAwo}
              showDeactivated={showDeactivated}
              shrink
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              onClick={() => {
                localStorage.setItem('awoId', adminAwo?.id || '');
                localStorage.setItem('awoName', adminAwo?.name || '');
                setAwoDialogOpen(false);
                window.location.reload();
              }}
            >
              Change view
            </Button>
            <Button variant="text" onClick={() => setAwoDialogOpen(false)}>Cancel</Button>
            <Box>
              <FormControlLabel
                control={(
                  <Switch
                    checked={showDeactivated}
                    onChange={handleShowDeactivatedChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size="small"
                  />
)}
                label="Show deactivated"
              />
            </Box>
          </CardActions>
        </Dialog>
      </Box>
    </AwoContext>
  );
}

export default WithSideNav;
