import {
  Autocomplete,
  FormHelperText, TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { isEqual } from 'lodash';
import { TextFieldElement } from 'react-hook-form-mui';
import * as yup from 'yup';
import { AutocompleteControl } from '../FormControls';
import countriesOptions from '../../resources/countriesData';
import { AwoFormStateOption, AwoFormTimezoneOption } from '../../types/Awo';

export const locationSchema = {
  country: yup.object({
    label: yup.string(),
    value: yup.string(),
  }).required('Country is required'),
  city: yup.string().required('City is required'),
  state: yup.object({
    label: yup.string(),
    abbreviation: yup.string(),
  }).required('Required'),
  postal: yup.string().matches(/(^\d{5}$)|(^\d{4}$)|(^\w{3} \w{3}$)/, 'Postal code is invalid').required('Postal code is required'),
};

function LocationPartial(
  {
    control,
    errors,
    setValue,
    selectedCountryOption,
    setSelectedCountryOption,
    defaultValues,
  }:
  {
    control: Control<any, any>,
    errors: FieldErrors<{
      country: {
        label: string;
        value: string;
        timezones: AwoFormTimezoneOption[];
        states: AwoFormStateOption[];
      } | null;
      address: string;
      city: string;
      state: AwoFormStateOption | null;
      postal?: number;
    }>,
    setValue: (field: any, value: any) => void,
    selectedCountryOption: Record<'label' | 'value' | 'timezones' | 'states', any> | null,
    setSelectedCountryOption: (value: any) => void,
    defaultValues?: Record<string, any>,
  },
) {
  const isProvince = selectedCountryOption?.value === 'Canada';

  useEffect(() => {
    const country = countriesOptions.find(
      (option) => option.value === defaultValues?.country || option.label === defaultValues?.country,
    );
    if (country) {
      setValue('country', country);
      const state = country.states.find((stateItem) => stateItem.abbreviation === defaultValues?.state);
      if (state) {
        setValue('state', state);
      }
    }
  }, [defaultValues?.country, defaultValues?.state, setValue]);

  return (
    <>
      <AutocompleteControl
        fieldName="country"
        control={control}
        render={({ onChange, ...props }) => (
          <>
            <Autocomplete
              onChange={
                (event, value) => {
                  onChange({ target: { name: 'country', value } });
                  setSelectedCountryOption(value);
                  setValue('state', null);
                  setValue('timezone', null);
                }
              }
              {...props}
              sx={{ marginTop: '16px' }}
              options={countriesOptions}
              defaultValue={defaultValues?.country}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Select country"
                  label="Country"
                />
              )}
              isOptionEqualToValue={(...comp) => isEqual(...comp)}
              color={errors.country ? 'error' : 'primary'}
            />
            {errors.country && (
              <FormHelperText error>{errors.country?.message}</FormHelperText>
            )}
          </>
        )}
      />
      <TextFieldElement
        name="address"
        sx={{ marginTop: '16px' }}
        fullWidth
        label="Address (optional)"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <TextFieldElement
        name="city"
        sx={{ marginTop: '16px' }}
        fullWidth
        label="City"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
      <AutocompleteControl
        fieldName="state"
        control={control}
        render={({ onChange, ...props }) => (
          <>
            <Autocomplete
              onChange={(event, value) => onChange({ target: { name: 'state', value } })}
              {...props}
              sx={{ marginTop: '16px' }}
              defaultValue={defaultValues?.state}
              options={selectedCountryOption?.states || []}
              renderInput={
                (params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    placeholder={isProvince ? 'Select province' : 'Select state'}
                    label={isProvince ? 'Province' : 'State'}
                  />
                )
              }
              isOptionEqualToValue={(...comp) => isEqual(...comp)}
              color={errors.state ? 'error' : 'primary'}
            />
            {errors.state && (
              <FormHelperText error>{errors.state?.message}</FormHelperText>
            )}
          </>
        )}
      />
      <TextFieldElement
        name="postal"
        sx={{ marginTop: '16px' }}
        fullWidth
        label="Postal code"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
}
export default LocationPartial;
