import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider, Skeleton, Snackbar, TextField, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { capitalize, get } from 'lodash';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import TaskCard from './TaskCard';
import {
  GET_TASKS, UPDATE_EMAIL, UPDATE_PHONE_NUMBER, UPDATE_TASK,
} from '../queries';
import ErrorMessage from './ErrorMessage';
import { beforeCapture, isInactiveAnimal } from '../utilities';

function SkeletonTaskCard() {
  return (
    <Card variant="outline" sx={{ border: '2px solid #462cd5', backgroundColor: 'white' }}>
      <CardContent>
        <Box sx={{ mb: 2.5 }}>
          <Box>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Next Step</Typography>
          <Skeleton variant="text" width="100%" />
        </Box>
        <Divider />
        <CardActions disableSpacing sx={{ p: 0 }}>
          <Skeleton variant="rectangle" width="80px" />
        </CardActions>
      </CardContent>
    </Card>
  );
}

const ROWS_PER_PAGE = 25;

function TasksList({
  setRefetch, setUserId, setDrawerOpen, showCompleted, careType,
  initStartDaysAgo = -1, initEndDaysAgo = -1, coordinatorFilters,
}) {
  const [showMoreTasks, setShowMoreTasks] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [phoneDialogOpen, setPhoneDialogOpen] = React.useState(false);
  const [petParent, setPetParent] = React.useState(null);
  const [task, setTask] = React.useState(null);
  const [newEmail, setNewEmail] = React.useState('');
  const [newPhone, setNewPhone] = React.useState('');
  const [newCountry, setNewCountry] = React.useState('us');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [sendingRequest, setSendingRequest] = React.useState(false);
  const [validNumber, setValidNumber] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const identity = useIdentityContext();
  const isAgent = get(identity, 'user.app_metadata.roles', []).includes('agent');

  const {
    loading, error, data, fetchMore,
  } = useQuery(GET_TASKS, {
    variables: {
      offset: 0,
      limit: ROWS_PER_PAGE,
      completed: showCompleted,
      startDaysAgo: initStartDaysAgo,
      endDaysAgo: initEndDaysAgo,
      careType,
      coordinatorIds: Array.from(coordinatorFilters),
    },
  });

  const [updateTask] = useMutation(UPDATE_TASK, {
    onCompleted: (d) => {
      if (setRefetch) {
        setRefetch(true);
      }
      if (!snackbarOpen) {
        setSnackbarOpen(true);
        setSnackbarMessage(d.updateTask);
      }
    },
    refetchQueries: [
      'GetTasks',
    ],
  });

  const [updateEmail] = useMutation(UPDATE_EMAIL, {
    onCompleted: (d) => {
      setPetParent(null);
      setDialogOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage(d.updateEmail);
      if (d.updateEmail.startsWith('Successfully updated')) {
        updateTask({ variables: { id: task.id, completed: true } });
      }
      setTask(null);
      setSendingRequest(false);
    },
  });

  const [updatePhoneNumber] = useMutation(UPDATE_PHONE_NUMBER, {
    onCompleted: (d) => {
      setPetParent(null);
      setPhoneDialogOpen(false);
      setSnackbarOpen(true);
      setSnackbarMessage(d.updatePhoneNumber);
      if (d.updatePhoneNumber.startsWith('Successfully updated')) {
        updateTask({ variables: { id: task.id, completed: true } });
      }
      setTask(null);
      setSendingRequest(false);
    },
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const indices = [];
  for (let i = 0; i < 10; i += 1) {
    indices.push(i);
  }

  if (loading || !data) {
    return (
      <>
        {indices.map((i) => (
          <Box sx={{ pb: 3 }} key={i}>
            <SkeletonTaskCard />
          </Box>
        ))}
      </>
    );
  }
  if (error) throw error;

  const taskCards = data.tasks.map((t) => {
    const buttons = [];
    if (!isAgent) {
      buttons.push((
        <Button
          key={`${t.id}-done`}
          onClick={() => {
            updateTask({ variables: { id: t.id, completed: !t.completed } });
          }}
        >
          {`Mark as ${t.completed ? 'unresolved' : 'done'}`}
        </Button>
      ));
    }
    if (t.actionText.includes('typo') && !isAgent) {
      buttons.unshift((
        <Button
          key={`${t.id}-review`}
          onClick={() => {
            setDialogOpen(true);
            setTask(t);
            setPetParent(t.petParent);
          }}
        >
          Submit new email address
        </Button>
      ));
    } else if (t.actionText.includes('invalid mobile number') && !isAgent) {
      buttons.unshift((
        <Button
          key={`${t.id}-review`}
          onClick={() => {
            setPhoneDialogOpen(true);
            setTask(t);
            setPetParent(t.petParent);
          }}
        >
          Submit new mobile number
        </Button>
      ));
    } else if (t.actionText.includes('out of 5') && !isAgent) {
      buttons.unshift((
        <Button
          key={`${t.id}-review`}
          onClick={() => {
            navigator.clipboard.writeText(t.petParent.email);
            setSnackbarMessage(`Copied ${t.petParent.email} to your clipboard`);
            setSnackbarOpen(true);
          }}
        >
          Copy Email
        </Button>
      ));
    }
    const animalText = t.petParent.animals.filter((a) => !isInactiveAnimal(a)).map((a) => `${capitalize(a.name)} ${a.animalType === 'Dog' ? '🐶' : '🐱'}`).join(', ');
    return {
      id: t.id,
      completed: t.completed,
      petParent: t.petParent,
      actionText: t.actionText,
      subtext: `${animalText ? `${animalText} • ` : ''}${moment(t.dateCreated).format('MMM D, YYYY')}`,
      nextStep: t.nextStep,
      actions: buttons,
      outline: true,
      onClick: (e) => {
        if (e.target.nodeName === 'BUTTON') {
          e.preventDefault();
        } else {
          setDrawerOpen(true);
          setUserId(t.petParent.id);
        }
      },
    };
  });

  const handleShowMoreTasks = () => {
    if (isDesktop) {
      setPage(page + 1);
      fetchMore({
        variables: {
          offset: (page + 1) * ROWS_PER_PAGE,
          limit: ROWS_PER_PAGE,
        },
      });
    } else {
      setShowMoreTasks(!showMoreTasks);
    }
  };

  return (
    <>
      {taskCards.slice(0, isDesktop || showMoreTasks ? taskCards.length : 3).map((tc) => (
        <Box sx={{ pb: 3 }} key={tc.id}>
          <TaskCard
            completed={tc.completed}
            petParent={tc.petParent}
            actionText={tc.actionText}
            subtext={tc.subtext}
            nextStep={tc.nextStep}
            actions={tc.actions}
            outline={tc.outline}
            onClick={tc.onClick}
          />
        </Box>
      ))}
      {data?.taskCount?.count > (page + 1) * ROWS_PER_PAGE ? (
        <Box>
          <Button variant="text" onClick={handleShowMoreTasks}>{`Show ${showMoreTasks ? 'less' : 'more'}`}</Button>
        </Box>
      ) : null}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="new-email-title"
        aria-describedby="new-email-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="new-email-title">
          Submit new email
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="new-email-description">
            {`Enter a correct email address for ${petParent?.email}`}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={sendingRequest}
            variant="contained"
            onClick={() => {
              setSendingRequest(true);
              updateEmail({ variables: { oldEmail: petParent?.email, newEmail } });
            }}
          >
            Submit
          </Button>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={phoneDialogOpen}
        onClose={() => setPhoneDialogOpen(false)}
        aria-labelledby="new-phone-title"
        aria-describedby="new-phone-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            overflowY: 'unset',
          },
        }}
      >
        <DialogTitle id="new-phone-title">
          Submit new mobile number
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'unset' }}>
          <DialogContentText id="new-phone-description">
            {`Enter the correct mobile number for ${petParent?.email}`}
          </DialogContentText>
          <br />
          <PhoneInput
            country={newCountry}
            disableCountryGuess
            onlyCountries={['us', 'au', 'ca']}
            placeholder="Phone Number"
            value={newPhone}
            onChange={(phone, country) => {
              setNewPhone(phone);
              setNewCountry(country.countryCode);
            }}
            isValid={(value, country) => {
              const isValid = value.length === country.format.replace(/[^.]/g, '').length;
              setValidNumber(isValid);
              return isValid;
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            disabled={sendingRequest || !validNumber}
            variant="contained"
            onClick={() => {
              setSendingRequest(true);
              updatePhoneNumber({ variables: { email: petParent?.email, newPhone } });
            }}
          >
            Submit
          </Button>
          <Button onClick={() => setPhoneDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        open={snackbarOpen}
        message={snackbarMessage}
        key={snackbarMessage}
      />
    </>
  );
}

export default Sentry.withErrorBoundary(TasksList, { fallback: <ErrorMessage />, beforeCapture });
