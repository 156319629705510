import { ChevronRight, Close } from '@mui/icons-material';
import * as yup from 'yup';
import { AnyObject } from 'yup';
import React, { ReactElement, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardHeader,
  Dialog, DialogActions,
  DialogContent,
  Grid, IconButton, Link,
  ListItem,
  ListItemButton,
  ListItemText, Snackbar,
  Typography,
} from '@mui/material';
import { FormContainer } from 'react-hook-form-mui';
import { parseAwoUpdateFormValues } from '../utilities';
import { UPDATE_AWO } from '../queries';
import { Awo, NewAwoFormValues } from '../types/Awo';

function LinkWrapper(
  { children, link }:
    { children: ReactElement, link?: { href: string, target?: string, rel: string } },
): ReactElement {
  if (link) {
    return (
      <Link sx={{ color: 'inherit' }} href={link.href} target={link.target} rel={link.rel} underline="none">
        {children}
      </Link>
    );
  }
  return children;
}
export default function SettingsListItem({
  awo,
  title,
  headings,
  defaultValues,
  schema, icon = <IconButton><ChevronRight sx={{ color: 'secondary.text' }} /></IconButton>,
  children,
  link,
  onClick,
}: {
  awo: Awo | Record<string, never>,
  title?: string,
  headings: {
    title: string,
    snackTitle?: string,
    description: string,
    displayValue: string | null,
    dialogDescription?: string,
  }[],
  defaultValues?: Record<string, any>,
  schema?: yup.ObjectSchema<any, AnyObject, any, ''>,
  icon?: React.ReactNode,
  children?: (control: any, errors: any, setValue: any, getValues: any) => JSX.Element,
  link?: { href: string, target?: string, rel: string },
  onClick?: () => void,
}): JSX.Element {
  const [dialog, setDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [updateAwo, { error }] = useMutation(UPDATE_AWO, {
    onCompleted: () => {
      setSnackbarOpen(true);
      setSnackbarMessage(`${headings[0].snackTitle || title || headings[0].title} saved`);
      setDialog(false);
    },
  });

  const {
    register, control, formState, setValue, getValues, ...rest
  } = useForm({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  });

  const { errors } = formState;

  const formSubmitHandler = async (values: Required<NewAwoFormValues>) => {
    const strictValues = parseAwoUpdateFormValues(values);
    await updateAwo({
      variables: {
        id: awo.id,
        awo: {
          ...strictValues,
        },
      },
    });
  };

  const isLink = (value: string | null) => {
    if (!value) return false;
    const r = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
    return r.test(value);
  };

  return (
    <>
      {
        headings.map((heading) => (
          <LinkWrapper link={link}>
            <ListItem
              key={heading.title}
              disablePadding
              secondaryAction={icon}
              onClick={() => (onClick ? onClick() : (children && setDialog(true)))}
            >
              <ListItemButton
                sx={{
                  pt: 1.5,
                  pb: 1.5,
                  pl: { xs: 0, md: 2 },
                }}
              >
                <ListItemText
                  sx={{ margin: 0 }}
                  primary={heading.title}
                  secondary={(
                    <>
                      <Typography component="span" sx={{ display: 'block' }} variant="body2" color="secondary.text">
                        {
                          !isLink(heading.displayValue)
                            ? heading.displayValue
                            : (
                              <a
                                onClick={(e) => e.stopPropagation()}
                                href={heading.displayValue || ''}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {heading.displayValue}
                              </a>
)
                        }
                      </Typography>
                      <Typography
                        component="span"
                        variant="caption"
                        color="secondary.text"
                      >
                        {heading.description}
                      </Typography>
                    </>
                  )}
                />
              </ListItemButton>
            </ListItem>
          </LinkWrapper>
          ))
      }
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
        maxWidth="md"
      >
        <FormContainer
          formContext={{
            register, control, formState, setValue, getValues, ...rest,
          }}
          onSuccess={formSubmitHandler as any}
        >
          <CardHeader
            title={title || headings[0].title}
            subheader={headings[0].dialogDescription || headings[0].description}
          />
          <DialogContent style={{ width: '585px', maxWidth: '82vw' }}>
            <Grid container flexDirection="column" spacing={2}>
              {children && children(control, errors, setValue, getValues)}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'start' }}>
            <Button type="submit" variant="contained">Save changes</Button>
            <Button onClick={() => setDialog(false)}>Cancel</Button>
          </DialogActions>
        </FormContainer>
      </Dialog>
      <Snackbar
        open={!!error}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={error?.message}
      >
        <Alert severity="error">{'Error in updating the organization info. We\'re working on it.'}</Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        action={<Close sx={{ cursor: 'pointer' }} onClick={() => setSnackbarOpen(false)} />}
        message={snackbarMessage}
      />
    </>
  );
}
