import React from 'react';
import { TextFieldElement } from 'react-hook-form-mui';

function NotePartial() {
  return (
    <TextFieldElement
      name="note"
      sx={{ marginTop: '16px' }}
      fullWidth
      label="Note (Optional)"
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
}
export default NotePartial;
