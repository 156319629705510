import React from 'react';
import {
  Box,
  Button, Checkbox,
  Dialog, DialogActions,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography, CardHeader, Snackbar, Alert, CardContent,
} from '@mui/material';
import {
  Add, Search, Close,
} from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { GET_AWOS } from '../../queries';
import OrganizationsTable from '../OrganizationsTable';
import { Awo } from '../../types/Awo';
import { OrganizationsTableFields } from '../../types/Tables';
import NewOrganizationModal from './NewOrganizationModal';
import SmallScreenPlaceholder from '../SmallScreenPlaceholder';

function OrganizationsContainer() {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState<OrganizationsTableFields>(null);
  const [order, setOrder] = React.useState<'desc' | 'asc' | undefined>(undefined);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [orgStatus, setOrgStatus] = React.useState<Set<string>>(new Set<string>());
  const [orgType, setOrgType] = React.useState<Set<string>>(new Set<string>());
  const [orgSMSName, setOrgSMSName] = React.useState<Set<string>>(new Set<string>());
  const [appliedFilters, setAppliedFilters] = React.useState<Record<'status' | 'type' | 'smsName', string[]>>({
    status: [],
    type: [],
    smsName: [],
  });
  const [organizations, setOrganizations] = React.useState<{ list: Awo[], count: number }>({
    list: [],
    count: 0,
  });
  const [newOrganizationDialog, setNewOrganizationDialog] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [getAwosList, {
    loading, error, data, networkStatus, refetch,
  }] = useLazyQuery<{ awos: Awo[], awosCount: { count: number } }>(GET_AWOS, {
    variables: {
      filters: {
        name: searchText,
      },
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      order,
      orderBy,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleChangeOrder = (_: any, field: OrganizationsTableFields) => {
    const isAsc = orderBy === field && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(field);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleCloseFilters = () => {
    setOpen(false);
    setOrgType(new Set<string>());
    setOrgStatus(new Set<string>());
  };

  const handleApplyFilters = () => {
    setOpen(false);
    setAppliedFilters({
      status: orgStatus.size > 0 ? Array.from(orgStatus) : [],
      type: orgType.size > 0 ? Array.from(orgType) : [],
      smsName: orgSMSName.size > 0 ? Array.from(orgSMSName) : [],
    });
    setOrgStatus(new Set<string>());
    setOrgType(new Set<string>());
    setOrgSMSName(new Set<string>());
    setPage(0);
  };

  const handleResetFilters = () => {
    setAppliedFilters({
      status: [],
      type: [],
      smsName: [],
    });
    setPage(0);
  };

  // initial load
  React.useEffect(() => {
    getAwosList();
  }, [getAwosList]);

  React.useEffect(() => {
    refetch({
      filters: {
        name: searchText,
        status: appliedFilters.status.length > 0 ? appliedFilters.status : undefined,
        membershipType: appliedFilters.type.length > 0 ? appliedFilters.type : undefined,
        smsName: appliedFilters.smsName.length > 0 ? appliedFilters.smsName : undefined,
      },
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      order,
      orderBy,
      searchText,
    });
  }, [order, orderBy, refetch, searchText, appliedFilters, rowsPerPage, page]);

  React.useEffect(() => {
    if (data?.awos && !Number.isNaN(data?.awosCount?.count)) {
      setOrganizations({
        list: data.awos,
        count: data.awosCount.count,
      });
    }
  }, [data]);

  if (error) throw error;

  const options = {
    status: {
      label: 'Status',
      getState: orgStatus,
      setState: setOrgStatus,
      variants: [{
        label: 'Active',
        value: 'active',
      }, {
        label: 'Deactivated',
        value: 'deactivated',
      }],
    },
    type: {
      label: 'Type',
      getState: orgType,
      setState: setOrgType,
      variants: [{
        label: 'Basic',
        value: 'Basic',
      }, {
        label: 'Plus',
        value: 'Plus',
      }],
    },
    smsName: {
      label: 'SMS',
      getState: orgSMSName,
      setState: setOrgSMSName,
      variants: [{
        label: 'ASM',
        value: 'Animal Shelter Manager',
      }, {
        label: 'Animals First',
        value: 'Animals First',
      }, {
        label: 'Chameleon',
        value: 'Chameleon',
      }, {
        label: 'Pawlytics',
        value: 'Pawlytics',
      }, {
        label: 'Pethealth',
        value: 'Pethealth',
      }, {
        label: 'Petpoint',
        value: 'Petpoint',
      }, {
        label: 'Petstablished',
        value: 'Petstablished',
      }, {
        label: 'Shelter Buddy',
        value: 'Shelter Buddy',
      }, {
        label: 'Shelterluv',
        value: 'Shelterluv',
      }, {
        label: 'Other',
        value: 'Other',
      }],
    },
  };

  return (
    <Box sx={{
        padding: 3,
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Typography variant="overline">Organizations</Typography>
      </Box>
      <Grid container>
        <Grid
          item
          xs={0}
          lg={12}
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Grid container>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gap={2}
                >
                  <Box width="30%" maxWidth="300px">
                    <TextField
                      fullWidth
                      margin="normal"
                      name="text-search"
                      label="Search by name"
                      type="text"
                      id="text-search"
                      variant="standard"
                      value={searchText}
                      onChange={(e) => {
                        setPage(0);
                        setSearchText(e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="search">
                              <Search />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  gap={1}
                >
                  {order === undefined && orderBy === null ? (null) : (
                    <Box>
                      <Button
                        startIcon={<Close />}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setOrder(undefined);
                            setOrderBy(null);
                          }}
                      >
                        Clear Sorting
                      </Button>
                    </Box>
                    )}
                  <Box>
                    {(appliedFilters.status.length > 0
                      || appliedFilters.type.length > 0
                      || appliedFilters.smsName.length > 0
                    ) ? (
                      <Button
                        startIcon={<Close />}
                        onClick={handleResetFilters}
                        variant="outlined"
                        size="small"
                      >
                        Clear filters
                      </Button>
                    ) : (
                      <Button
                        startIcon={<FilterAltOutlinedIcon />}
                        onClick={() => setOpen(true)}
                        variant="outlined"
                        size="small"
                      >
                        Filters
                      </Button>
                    )}
                  </Box>
                  <Box>
                    <Button
                      onClick={() => setNewOrganizationDialog(true)}
                      variant="contained"
                      size="small"
                      startIcon={<Add />}
                    >
                      New organization
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <OrganizationsTable
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                order={order}
                orderBy={orderBy}
                handleChangeOrder={handleChangeOrder}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                count={organizations.count}
                loading={loading}
                organizations={organizations.list}
                networkStatus={networkStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <SmallScreenPlaceholder />
      </Grid>
      <Dialog
        open={open}
        onClose={handleCloseFilters}
        aria-labelledby="filter-dialog-title"
        aria-describedby="filter-dialog-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: '585px',
            maxWidth: '90vw',
          },
        }}
      >
        <CardHeader title="Filters" subheader="Filter organizations by specific variables" />
        <CardContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            {
              Object.keys(options).map((key) => {
                const option = (options)[key as keyof typeof options];
                return (
                  <FormGroup sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" sx={{ color: 'secondary.text' }}>{option.label}</Typography>
                    {
                        option.variants.map((variant) => (
                          <FormControlLabel
                            sx={{
                              marginLeft: 0,
                            }}
                            control={(
                              <Checkbox
                                checked={
                                  option.getState.has(variant.value)
                                  || appliedFilters[key as keyof typeof options].includes(variant.value)
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    option.getState.add(variant.value);
                                  } else {
                                    option.getState.delete(variant.value);
                                  }
                                  option.setState(new Set(option.getState));
                                }}
                              />
                            )}
                            label={variant.label}
                          />
                        ))
                      }
                  </FormGroup>
                );
              })
            }
          </Box>
        </CardContent>
        <DialogActions sx={{ justifyContent: 'start' }}>
          <Button variant="contained" disabled={orgType.size === 0 && orgStatus.size === 0 && orgSMSName.size === 0} onClick={handleApplyFilters}>Apply Filters</Button>
          <Button onClick={handleCloseFilters}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen open={newOrganizationDialog}>
        <NewOrganizationModal
          onClose={(awoName?: string) => {
            setNewOrganizationDialog(false);
            if (awoName) {
              setSnackbarMessage(`AWO: ${awoName} was successfully created`);
              setSnackbarOpen(true);
            }
          }}
        />
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        key={snackbarMessage}
      >
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </Box>
  );
}

export default OrganizationsContainer;
